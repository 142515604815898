.container {
  display:flex;
  flex-direction:column;
  align-items:center;
}
body {
  background:#29225b;
}
div {
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  margin:2% auto;
  border: 5px solid #4b4889;
  background:#be6590;
  flex-basis:auto;
}
p {
  color:white;
  padding:1%;
  margin:1%;
}
h1 {
  color:white;
  text-align:center;
}
img {
  display:block;
  margin:auto;
  height:400px;
  width:300px;
}
a {
  color:#fff;
  text-decoration:underline;
}
a:hover {
  color:#4b4889;
}
a:visited {
  color:#29225b;
}
.contact {
  display:block;
  margin:auto;
  text-align:center;
}
.contact a {
  text-decoration:none;
}
p.contact {
  padding:0;
}
div.credits {
  margin-top:10%;
  height:10%;
  font-size:x-small;
}
div.credits p {
  margin:0 auto;
}
span.asterisk {
  color:#29225b !important;
}
.mailinglist {
  width:50%;
}
.mailinglist p {
  width:50%;
  margin:0px auto;
}
.mailinglist p input {
  float:right;
}
input.email {
  margin-top:2%;
}
/* .indicates-required { */
/*   margin:0px !important; */
/*   font-size:16px !important; */
/* } */
form h2 {
  text-align:center;
  color:#fff;
}
#mce-success-response {
color:#fff !important;
}
